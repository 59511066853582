<template>
  <div
    class="container"
    v-title
    :data-title="$t('i18n.qrCodeToll')"
    id="qrCodeToll"
  >
    <div id="outer-title">{{ $t("i18n.qrCodeToll") }}</div>
    <jl-form :columns="columns" @onSubmit="onSubmit"></jl-form>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import jlForm from "../../components/form";
export default {
  name: "QuickMark",
  components: {
    jlForm,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const simpleEleType = [
      { name: "直梯", locale: "elevator" },
      { name: "液压梯", locale: "hydraulicLadder" },
      { name: "扶梯", locale: "escalator" },
      { name: "自动人行道梯", locale: "automaticSidewalk" },
    ];
    const state = reactive({
      columns: [
        // 需要展示的列
        {
          prop: "contractNumber",
          label: "contractNo",
          type: "remote",
          props: { label: "number", value: null },
          loading: false,
          data: [],
          remoteMethod: (val) => {
            remoteContractMethod(val, "columns", "contractNumber");
          },
          change: (val) => {
            setData("contractNumber", val);
          },
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "elevatorType",
          label: "elevatorType",
          type: "select",
          props: { label: "locale", value: "name" },
          data: simpleEleType,
          change: (val) => {
            setData("elevatorType", val);
          },
          rules: [{ required: true, message: t("i18n.select") }],
        },
        {
          prop: "equipmentNumber",
          label: "elevatorNo",
          type: "select",
          multiple: true,
          props: { label: "equipmentNumber", value: "equipmentNumber" },
          data: [],
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "codePosition",
          label: "codeLocation",
          type: "select",
          props: { label: "signName", value: null },
          data: [],
          value: [],
          multiple: true,
          change: (val) => {
            state.columns[4].data = val;
          },
          rules: [{ required: true, message: t("i18n.select") }],
        },
        {
          prop: "qrTag",
          label: "lableStyle",
          type: "select",
          props: { label: "signName", value: "signName" },
          data: [],
          rules: [{ required: true, message: t("i18n.select") }],
        },
        {
          prop: "companySlogan",
          label: "companySlogan",
          type: "input",
        },
        {
          prop: "companyTEL",
          label: "companyTEL",
          type: "input",
        },
        {
          prop: "maintenancePhone",
          label: "maintenancePhone",
          type: "input",
        },
      ],
      codeSign: "",
    });

    const setData = async (key, val) => {
      state[key] = val;
      let index = state.columns.findIndex((item) => {
        return item.prop === "equipmentNumber";
      });
      if (state.contractNumber && state.elevatorType) {
        let { data } = await proxy.$api.system.getPartElevator({
          upkeepId: state.contractNumber.id,
          elevatorType: state.elevatorType,
        });
        data.length > 0
          ? (state.columns[index].data = data)
          : proxy.$defined.tip(t("i18n.noMatchingElevatorFound"));
      }
    };

    const getCodeSign = async () => {
      let index = state.columns.findIndex((item) => {
        return item.prop === "codePosition";
      });
      let { data } = await proxy.$api.system.getCodeSign();
      state.columns[index].data = state.codeSign = data;
    };
    getCodeSign();

    const onSubmit = async (row) => {
      let data = {};
      for (let key in row) {
        if (key === "contractNumber") {
          data[key] = row[key].number;
        } else if (key === "codePosition") {
          let signTag = [];
          let codePosition = [];
          row[key].map((item) => {
            signTag.push(item.signTag);
            codePosition.push(item.signName);
          });
          data.signTag = signTag.join(",");
          data.codePosition = codePosition.join(",");
        } else if (key === "equipmentNumber") {
          data[key] = row[key].join(",");
        } else {
          data[key] = row[key];
        }
      }
      let res = await proxy.$api.system.getCodeExport(data);
      proxy.$defined.exportFunc(res);
    };

    const remoteContractMethod = (val, column, key) => {
      var index = state[column].findIndex((item) => {
        return item.prop === key;
      });
      if (val) {
        state[column][index].loading = true;
        setTimeout(() => {
          proxy.$api.system.getContractData({ number: val }).then((data) => {
            state[column][index].data = data.data;
            state[column][index].loading = false;
          });
        }, 1000);
      } else {
        state[column][index].data = [];
      }
    };

    return {
      ...toRefs(state),
      onSubmit,
      remoteContractMethod,
      setData,
    };
  },
};
</script>

<style lang="scss">
#qrCodeToll {
  .form-button {
    text-align: left;
  }
}
@media screen and (max-width: 768px) {
  #qrCodeToll {
    .form-button {
      text-align: center;
    }
  }
}
</style>
